body {
    background-color: #f0f2f5;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.test-status-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    margin: 40px auto;
}

.test-status-page h1 {
    margin-bottom: 20px;
}

.test-status-page-tables {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    width: 100%;
}

.test-status-page-table {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    background-color: #f8f9fa;
}

.test-status-page-table h2 {
    margin-top: 0;
    margin-bottom: 5px;
}

.test-status-page-table h3 {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 0.9rem;
    font-weight: 500;
    color: #6c757d;
}

table {
    width: 100%;
    border-collapse: collapse;
}

table th,
table td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ccc;
}

table th {
    background-color: #f0f0f0;
    font-weight: bold;
}

table tbody tr:nth-child(even) {
    background-color: #f8f9fa;
}

button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 3px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

button:hover {
    background-color: #0056b3;
}

.api-intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    text-align: center;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 5px;
    margin-bottom: 20px;
    max-width: 800px;
}

.api-intro h1 {
    font-size: 2rem;
    margin-bottom: 10px;
}

.api-intro p {
    margin-bottom: 10px;
}

.api-intro a {
    color: #007bff;
    text-decoration: underline;
}

.api-intro a:hover {
    text-decoration: none;
}